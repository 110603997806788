<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full p-10"
        >
          <div class="text-center">
            <span
              v-if="!donne.error"
              class="text-oho-green text-c24 font-c7"
            >Confirmation</span>
            <span
              v-if="donne.error"
              class="text-red-alert text-c24 font-c7"
            >Attention</span>
          </div>

          <div class="text-center text-c18 font-c4 mt-6">
            <div
              v-if="!donne.error"
              class="pl-2 pr-2"
            >
              Etes-vous sûr(e) de vouloir vous connecter depuis l’agence :
            </div>
            <div
              v-if="donne.error"
              class=""
            >
              Vous êtes sur le point de vous connecter depuis un autre point de vente
            </div>
          </div>

          <div class="flex agence mt-6 items-center pl-6 pr-6">
            <icon
              :data="icons.ic_agence"
              height="40"
              width="40"
              class="mr-4"
              original
            />

            <div class="">
              <div class="text-c16 text-left text-lite-violet">
                Agence de :
              </div>
              <div class="text-c24 font-c6 text-dark-violet">
                {{ donne.agence }}
              </div>
            </div>
          </div>

          <div class="mt-10 flex">
            <div class="w-1/2">
              <buton
                background="#EAEAEF"
                color="#606060"
                label="NON"
                @oga="fermer"
              />
            </div>

            <div class="w-1/2 ml-6">
              <buton
                label="OUI"
                @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'

export default {
  name: 'Success',
  components: { PopupBase, buton },
  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        ic_agence,
      }
    }
  },
  created() {
    this.wantToMakeOffer = this.activation
  },
  methods: {
    fermer () {
      this.$emit('oga', false)
    },
    continuer () {
      this.$emit('oga', true)
    },
    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 40%;
    }
    .agence {
      background: #EFF3FE;
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 8.03241px;
      height: 79.52px;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
